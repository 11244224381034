import { CustomEntitySchema } from 'mosaiq/custom-entities/types';
import { NavigationItem } from 'types/api';
import { Org, OrgActionsModel, Person } from 'types/entities';
import { WithRequired } from 'types/utils';

export type LoginState = 'unauthed' | 'onboarding' | 'authed' | 'unknown';

export type Flags = Record<string, unknown>;

export type SemanticVersionString = `${number}.${number}.${number}`;

export type OAuthProvider = 'google' | 'microsoft' | 'email' | 'okta';

export type SignupTrack = 'ic' | 'lead' | 'hr';

// match AUTH_STEPS from backend-common/src/constants.ts
export enum AuthSteps {
  auth = 'auth',
  customDomainHandshake = 'customDomainHandshake',
  pickOrg = 'pickOrg',
  signupOrg = 'signupOrg',
  signupFinish = 'signupFinish',
  freeTrialRequested = 'freeTrialRequested',
  freeTrialApproved = 'freeTrialApproved',
  freeTrialRejected = 'freeTrialRejected',
  paymentMethodRequired = 'paymentMethodRequired',
  app = 'app',
}

export const SignupSteps: Set<Partial<AuthSteps>> = new Set([
  AuthSteps.signupOrg,
  AuthSteps.signupFinish,
  AuthSteps.freeTrialRequested,
  AuthSteps.freeTrialRejected,
  AuthSteps.freeTrialApproved,
]);

export interface SessionOrg extends Org {}

export type CandidateOrg = {
  id: string;
  is_auth_restricted: boolean;
  is_guest: boolean;
  is_invited: boolean;
  is_member: boolean;
  is_requested: boolean;
  logo_url: string;
  badge_url: string;
  name: string;
  person_count: number;
  subdomain: string;
};

export interface SessionPerson extends WithRequired<Person, 'org_role'> {
  email_address?: string;
  flags?: Flags | null;
  last_logged_in_at?: string;
  location_id?: string;
  org_actions: OrgActionsModel;
  signed_up_date?: string;
  limited_queries_remaining?: number;
}

export type StaticSessionData = {
  api_version: SemanticVersionString;
  client_name: string;
  client_version: SemanticVersionString;
  csrf_token: string;
};

export type BaseSessionData = StaticSessionData & {
  cdn_url: string;
  commit: string;
  environment: NodeJS.ProcessEnv['NODE_ENV'];
  flow_id?: string;
  flow_time?: string;
  host: string;
  intercom_id?: string;
  is_authenticated: boolean;
  openreplay_tracker_id?: string;
  org: Pick<Org, 'auth_methods' | 'integrations_data'>;
  render_page?: string;
  sentry?: Record<string, unknown>;
  stripe?: {
    public_key: string;
  };
  monday?: {
    oauth_client_id: string;
  };
  unsplash?: {
    accessKey: string;
  };
};

export type OnboardingSessionData = BaseSessionData & {
  person: SessionPerson;
  current_step: AuthSteps;
};

export type SessionData = Omit<BaseSessionData, 'org'> & {
  agreed_terms?: boolean;
  candidate_orgs?: CandidateOrg[];
  city?: string;
  country_id?: number;
  email_opt_in: boolean;
  entity_types: CustomEntitySchema[];
  limited_queries_remaining?: number;
  user_id: string;
  is_anon?: boolean;
  is_invited?: boolean;
  is_signed_up: boolean;
  is_verified: boolean;
  login_host: string;
  navigation_items?: NavigationItem[];
  oauth_provider?: OAuthProvider;
  onboarding_topics?: string[];
  // TODO:(PR) Make these optional
  org: SessionOrg;
  person: SessionPerson;
  person_id?: string;
  signup_track?: SignupTrack;
  statsig_client_id?: string;
  timezone?: string;
  vapid_public_key: string;
};

export type Session<L extends LoginState = 'authed'> = L extends 'onboarding'
  ? Partial<SessionData> & OnboardingSessionData
  : L extends 'authed'
    ? SessionData
    : Partial<SessionData> & BaseSessionData;
