import axios from 'axios';

import config from 'config';

export async function csrfRefresh(): Promise<string> {
  const {
    data: { csrf },
  } = await axios.get(config.envConfig.endpoints.auth.csrf);

  return csrf;
}
