import axios from 'axios';
import config from 'config';

import { uncacheMetadata } from 'store/metadata';
import { csrfRefresh } from './csrfRefresh';
import { setCsrfHeaders } from './setCsrfHeaders';

export async function authCheck() {
  try {
    const {
      data: { host, is_authenticated, is_subdomain_ok, is_token_ok },
    } = await axios.get(config.envConfig.endpoints.auth.isAuthenticated);

    if (!is_authenticated) {
      // Ensure new sessions never pick up old metadata
      uncacheMetadata();
      window.location.href = '/login?errno=102&status=401';
    } else if (!is_subdomain_ok) {
      window.location.href = `https://${host}/`;
    } else if (!is_token_ok) {
      const csrfToken = await csrfRefresh();
      setCsrfHeaders(csrfToken);
    }
  } catch (_) {}
}
