import { ApolloLink, FetchResult, NextLink, Operation } from '@apollo/client';
import { Observable, ObservableSubscription } from '@apollo/client/utilities';

import { readMetadataFromCache } from 'store/metadata';
import { authCheck } from './auth-check';

export class CSRFLink extends ApolloLink {
  request(operation: Operation, forward: NextLink) {
    return new Observable<FetchResult>((observer) => {
      let subscription: ObservableSubscription;

      const handleAuthError = async () => {
        try {
          await authCheck();

          const csrfToken = readMetadataFromCache()?.csrf_token;

          const context = operation.getContext();
          const headers = context.headers || {};

          operation.setContext({
            headers: {
              ...headers,
              'x-qatalog-csrf-token': csrfToken,
            },
          });

          // Retry the operation
          subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        } catch (e) {
          observer.error(e);
        }
      };

      subscription = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: (error) => {
          // Check if the error is related to authentication
          if (error?.response?.status === 401 && document.visibilityState === 'visible') {
            handleAuthError();
          } else {
            observer.error(error);
          }
        },
        complete: observer.complete.bind(observer),
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    });
  }
}

export const csrfLink = new CSRFLink();
